import axios from "axios";
import router from "@/router";
import store from "@/store";
// import { message } from "ant-design-vue";
import { Message } from "element-ui";
import { removeCache } from 'U'

const http = axios.create({
  baseURL: '',
  timeout: 360000
});

http.interceptors.request.use(
  config => {
    if(store.state.token) {
      config.headers.Authorization = store.state.token;
      // console.log(config)
      let numurl = /^(\/bigdata\/).+$/;
        if (!numurl.test(config.url)) {
          if (config.params && Object.keys(config.params).length) {
            for (let key in config.params) {
              if (typeof config.params[key] == 'number') {
                config.params[key] = config.params[key].toString();
              }
            }
          }
          if (config.data && Object.keys(config.data).length) {
            for (let key in config.data) {
              if (typeof config.data[key] == 'number') {
                config.data[key] = config.data[key].toString();
              }
            }
          }
        }
      }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

http.interceptors.response.use(
  response => {
    // console.log(response.config.data)
    if(response.config && response.config.responseType == 'blob') {
      //console.log(response.headers.filename)
      let responseFilename = response.headers.filename;
      if(responseFilename) {
        responseFilename = decodeURIComponent(responseFilename);
      }
      const blob = new Blob([response.data], { type: 'application/octet-stream' }); //后台需要相同的type
      let filenamepre = '导出数据.xls';
      if(response.config.data) {
        let dataObj = JSON.parse(response.config.data);
        if(dataObj.filename) {
          filenamepre = dataObj.filename;
        }
      }
      let filename = `${responseFilename||filenamepre}`;
      if ('download' in document.createElement('a')) {
        const downloadElement = document.createElement('a');
        let href = '';
        if(window.URL) href = window.URL.createObjectURL(blob);
        else href = window.webkitURL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = filename;
        document.body.appendChild(downloadElement);
        downloadElement.click();
        if(window.URL) window.URL.revokeObjectURL(href);
        else window.webkitURL.revokeObjectURL(href);
        document.body.removeChild(downloadElement);
      } else {
        navigator.msSaveBlob(blob, filename);
      }
      return Promise.resolve(response.data);
    }else {
      return response.data;
    }
  },
  error => {
    store.commit('setLoading', false);
    if (error.response && error.response.status == 401) {
      // 防止提示信息弹出多次
      if (store.state.token) {
        removeCache('logininfo', true);
        store.commit('main/resetTabs');
        store.commit("setToken");
        Message({
          showClose: true,
          message: '登陆超时，请重新登陆~',
          type: 'error'
        });
        setTimeout(() => {
          router.push({
            path: "/login"
          });
        }, 1000);
      }
      return error.response;
    }else {
      Message({
        showClose: true,
        message: '网络异常，请重试~',
        type: 'error'
      });
    }
  }
);

export default http;